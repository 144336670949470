import React from "react"
import styled from '@emotion/styled'

import Layout from "../components/layout"
import Projects from "../components/projects"
import Bookshelf from "../components/bookshelf"

function Header() {
  const HeaderContainer = styled.div`
    display: flex;
    width: 100%;  `
  return (
    <HeaderContainer>
    </HeaderContainer>
  )
}

export default function Home() {
  return (
    <Layout> 
      <Projects/>
    </Layout>
  )
}


